import { useCallback, useEffect, useMemo, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { debounce } from 'lodash';
import { DateRangePicker } from '../../transport-requests/FilterContainer';
import DateObject from 'react-date-object';
import Select, { SelectObject } from '../../../../_metronic/layout/components/select/Select';
import { getSortedList } from '../../../../utils/sort.utils';
import { getCompanies } from '../../../../setup/axios/users.request';
import { statusList } from '../../../../types/subscription.types';
import { StateSetter } from '../../../../utils/state.utils';
import { CompanyType } from '../../../../types/company.types';

type FilterContainerProps = {
  setSearchInput: (searchInput: string) => void;
  setSelectedTimeInterval: StateSetter<string[]>;
  selectedTimeInterval: string[];
  setSelectedCompanies: StateSetter<CompanyType[]>;
  selectedCompanies: CompanyType[];
  selectedStatus: string;
  setSelectedStatus: StateSetter<string>;
  searchInput: string;
};

const FilterContainer: React.FC<FilterContainerProps> = ({
  setSelectedCompanies,
  selectedCompanies,
  setSelectedTimeInterval,
  selectedTimeInterval,
  selectedStatus,
  setSelectedStatus,
  setSearchInput,
  searchInput,
}) => {
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const debouncedUpdateSearchInput = useMemo(() => {
    const debouncedFunction = debounce(setSearchInput, 600, {
      leading: false,
    });
    return debouncedFunction;
  }, []);

  const filterBySearch = (event) => {
    const newSearchInput = event.target.value.toLowerCase();
    debouncedUpdateSearchInput(newSearchInput);
  };

  const timeInterval = useMemo(
    () => selectedTimeInterval?.map((date: string) => new DateObject(date)),
    [selectedTimeInterval]
  );
  const setTimeInterval = useCallback((timeInterval: DateObject[]) => {
    setSelectedTimeInterval(
      (timeInterval || [])?.map((dateObject: DateObject) => dateObject.format())
    );
  }, []);

  useEffect(() => {
    if (companies?.length === 0) {
      (async () => {
        const response = await getCompanies({ onlyMainCompany: 1 });
        if (!response || response.error) {
          return;
        }
        setCompanies(response.companies);
      })();
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className='col-12 pb-4'>
      <div className='card h-100'>
        <div className='card-header pt-5 border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='row'>
            <div className='col py-3' style={{ minWidth: 200 }}>
              <label className='fw-bolder form-label pb-2 pt-1'>Search</label>
              <div className='input-group input-group-sm d-flex justify-content-center justify-content-xl-start'>
                <span className='input-group-text'>
                  <KTSVG
                    path='/media/icons/duotone/General/Search.svg'
                    className='svg-icon-2 svg-icon-gray-500'
                  />
                </span>
                <input
                  style={{ height: '38px' }}
                  placeholder='Package, status, dealer, user...'
                  className='form-control'
                  type='text'
                  onChange={filterBySearch}
                  defaultValue={searchInput}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col py-3' id='timeInterval' style={{ minWidth: 200 }}>
              {/* Time Interval */}
              <DateRangePicker timeInterval={timeInterval} setTimeInterval={setTimeInterval} />
            </div>
            <div className='col py-3' id='company'>
              <Select
                label='Company'
                value={selectedCompanies}
                options={getSortedList({ listToSort: companies || [], args: ['name'] })}
                onChange={(e: CompanyType[]) => {
                  setSelectedCompanies(e);
                }}
                isMulti={true}
                getOptionLabel={(option: CompanyType) => option.name}
                getOptionValue={(option: CompanyType) => option.id}
              />
            </div>
            <div className='col py-3' id='status'>
              <SelectObject
                label='Status'
                simpleValue={selectedStatus}
                options={getSortedList({ listToSort: statusList || [], args: ['name'] })}
                onChange={(status: string) => {
                  setSelectedStatus(status);
                }}
                isClearable={true}
                getOptionLabel={(option: { name: string }) => option?.name}
                getOptionValue={(option: { name: string }) => option?.name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;
