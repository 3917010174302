import {useCallback, useEffect, useMemo, useState} from 'react'
import ReactSelect from 'react-select/async'
import debounce from 'debounce-promise'
import {components} from 'react-select'

/**
 * Check documentation
 * https://github.com/jedwatson/react-select
 */

const Input = (props) => <components.Input {...props} isHidden={false} />

type SelectAsyncProps = {
  simpleValue: any
  onChange: (value: any) => void
  loadOptions: Function
  getOptionLabel: (item: any) => any
  getOptionValue: (item: any) => any
  [key: string]: any
}

const SelectAsync = (props: SelectAsyncProps) => {
  const [inputValue, setInputValue] = useState(props.simpleValue)
  const debouncedLoadOptions = useMemo(() => {
    const loadOptions = (inputValue: string) => props.loadOptions(inputValue)
    const debouncedFunction = debounce(loadOptions, 600, {
      leading: true,
    })
    return debouncedFunction
  }, [])

  const onInputChange = useCallback(
    (inputValue, {action}) => {
      // onInputChange => update inputValue
      if (action === 'input-change') {
        setInputValue(inputValue)
      } else if (action === 'menu-close') {
        setInputValue(props.simpleValue)
      }
    },
    [props.simpleValue]
  )

  const onChange = (optionsInstance) => {
    if (props.simpleValue === props.getOptionValue(optionsInstance)) {
      return
    }
    props.onChange(optionsInstance)
    setInputValue(props.getOptionLabel(optionsInstance))
  }

  return (
    <div data-bs-toggle='tooltip' title={props.simpleValue || ''}>
      <ReactSelect
        {...props}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        defaultOptions={[]}
        loadOptions={(inputValue: any) => debouncedLoadOptions(inputValue)}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        components={{Input}}
      />
    </div>
  )
}

export default SelectAsync
