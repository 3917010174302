import moment from 'moment';
import { clone } from './file.utils';
import {
  defaultPaymentStatus,
  defaultRequestStatus,
} from '../app/modules/add-edit-transport/scripts/transportDataUtils';
import { RentPlanDataType } from '../app/modules/add-edit-transport/scripts/tariffUtils';
import {
  TransportForRequestType,
  TransportType,
} from '../app/modules/add-edit-transport/scripts/transportTypes';
import { POI } from '../app/modules/poi-component/poiConstants';

const getAnonymousTransport = (transport: TransportType) => {
  return {
    ...transport,
    id: null,
    idTransportRequest: null,
    number: null,
    voucher: null,
    hash: null,
  };
};

export const getTransportForRepeat = (transport: TransportType) => {
  const clonedTransport = clone(transport);
  // remove all the refernces to the original transport
  const futureTransport = getAnonymousTransport(clonedTransport);

  futureTransport.effectiveDate = moment().format('YYYY-MM-DDTHH:mm:00.000');
  return getTrimmedTransportForNew(futureTransport);
};

export const getTransportForReturn = (transport: TransportType) => {
  const clonedTransport = clone(transport);
  // remove all the refernces to the original transport
  const futureTransport = getAnonymousTransport(clonedTransport);
  // switch destination with origin
  futureTransport.destination = transport.origin;
  futureTransport.destinationAdditionalInfo = transport.originAdditionalInfo;
  futureTransport.destinationCoords = transport.originCoords;
  futureTransport.destinationGeoFence = transport.originGeoFence;

  futureTransport.origin = transport.destination;
  futureTransport.originAdditionalInfo = transport.destinationAdditionalInfo;
  futureTransport.originCoords = transport.destinationCoords;
  futureTransport.originGeoFence = transport.destinationGeoFence;

  futureTransport.intermediaryPoints = transport.intermediaryPoints
    ? transport.intermediaryPoints.reverse()
    : [];
  futureTransport.intermediaryPoints = futureTransport.intermediaryPoints.map((POI, index) => ({
    ...POI,
    priority: index + 1,
  }));

  return getTrimmedTransportForNew(futureTransport);
};

export type TransportForDetails = {
  companyId: number | string;
  effectiveDate: string | Date;
  waitingTime: number | string;
  originGPS: string;
  originAddress: string;
  originAddressDetails: string;
  destinationGPS: string;
  destinationAddress: string;
  destinationAddressDetails: string;
  tariffServiceId: number | string;
  tariffTypeId: number | string;
  distance: number | string;
  estimatedDuration: number | string;
  intermediaryPoints: POI[];
  passengers: number | string;
  destination: string;
  blockedTariff: boolean;
  paymentType: string;
  transportRequestId: number | string;
  voucher?: string;
  supplierId: number | string;
  useNewIntercityFlow?: boolean;
  transportRequestType?: string;
};

const requiredFieldsForTariffBase = ['effectiveDate', 'originCoords', 'destinationCoords'];
const requiredFieldsForRentTariff = [...requiredFieldsForTariffBase, 'completeEstimateDate'];
const requiredFieldsForTariff = [...requiredFieldsForTariffBase, 'tariffServiceId'];

export const hasTariffFields = ({ transport }: { transport: TransportType }) => {
  return !requiredFieldsForTariff.some((key: string) => !transport[key]);
};

export const hasRentTariffFields = ({
  transport,
  rentPlanData,
}: {
  transport: TransportType;
  rentPlanData: RentPlanDataType;
}) => {
  const hasTransportFieldsForTariff = !requiredFieldsForRentTariff.some(
    (key: string) => !transport[key]
  );
  const hasRentDataValuesForTariff = !Object.entries(rentPlanData).some(([key, value]) => {
    if (key === 'discount' && value === 0) {
      // if it is discount, then 0 is a possible value
      return false;
    }
    // for every other field, if we have either an empty string, or 0, or null/undefined, it should return invalid value
    return !value;
  });
  return hasTransportFieldsForTariff && hasRentDataValuesForTariff;
};

export const getTransportForDetails = ({
  transport,
  voucher,
  intermPOIList = null,
}: {
  transport: TransportType | TransportForRequestType;
  voucher: string;
  intermPOIList?: POI[];
}): TransportForDetails => {
  const pricingPayload: TransportForDetails = {
    effectiveDate: transport.effectiveDate ? moment(transport.effectiveDate).toDate() : '',
    waitingTime: transport.estimatedWait || 0,
    originGPS: transport.originCoords || '',
    originAddress: transport.origin || '',
    originAddressDetails: transport.originAdditionalInfo || '',
    destinationGPS: transport.destinationCoords || '',
    destinationAddress: transport.destination || '',
    destinationAddressDetails: transport.destinationAdditionalInfo || '',
    tariffServiceId: transport.tariffServiceId || 0,
    tariffTypeId: transport.tariffTypeId || 0,
    distance: transport.distance || 0,
    estimatedDuration: transport.duration || 0,
    intermediaryPoints: intermPOIList || transport.intermediaryPoints || [],
    passengers: transport.passengers || 1,
    destination: transport.destination || '',
    blockedTariff: false,
    paymentType: transport.paymentType,
    transportRequestId: transport.idTransportRequest,
    companyId: transport.company?.id,
    supplierId: transport.supplier?.id,
    useNewIntercityFlow: false,
    transportRequestType: transport?.type,
  };
  if (voucher) {
    pricingPayload.voucher = voucher;
  }
  return pricingPayload;
};

export const getClientVoucherFromTransport = ({ transport }: { transport: TransportType }) => {
  const voucher = (transport?.transports || [])[0]?.client?.voucher;
  return voucher;
};

// keepAssignmentData is true for repeat on dates transports
export const getTrimmedTransportForNew = (
  transport: TransportType,
  keepAssignmentData?: boolean
): TransportType => {
  const resetAssignedData = {
    driverID: null,
    assignedDriver: null,
    assignedDriverId: null,
    assignedVehicle: null,
    assignedVehicleId: null,
  };
  return {
    ...transport,
    ...(keepAssignmentData ? {} : resetAssignedData),
    photos: [],
    pvs: [],
    transports: [],
    requestRemark: null,
    dispatchComment: '',
    billedCustomer: '',
    paymentNote: '',
    paymentStatus: defaultPaymentStatus,
    requestStatus: defaultRequestStatus,
    invoiceNumber: '',
    promoCode: '',
    feedback: null,
    feedbackScore: null,
    awaitingFeedback: true,
    startOdometer: 0,
    endOdometer: 0,
    driverKm: 0,
    startTime: null,
    endTime: null,
    googleReadyDistance: null,
    gpsReadyDistance: null,
    gpsKm: null,
    driverTransportStops: null,
    systemTransportStops: null,
    gpsMonitored: false,
    completeEstimateDate: null,
    greeterSignActivations: [],
    emissions: null,
    requestDate: moment().format('YYYY-MM-DDTHH:mm:00.000'),
    lastCheckedAt: null,
    lastCheckedBy: null,
    driverNotification: null,
  };
};

const getPriceTotal = (price, customText) =>
  `${
    price.number !== 0
      ? `• ${price.number} orders / ${price.price.toFixed(2)} ${customText} VAT\n`
      : ''
  }`;

export const getSelectedTransportsStatistics = (
  selectedTransports: TransportType[],
  returnWithoutVATObject: boolean = false
) => {
  const orders = {
    euroWithTva: { number: 0, price: 0 },
    euroWithoutTva: { number: 0, price: 0 },
    ronWithTva: { number: 0, price: 0 },
    ronWithoutTva: { number: 0, price: 0 },
  };
  selectedTransports.forEach((element) => {
    if (element?.tariff) {
      switch (true) {
        case element.currency === 'EUR' && element.paymentType === 'CONTRACT':
          orders.euroWithoutTva.number++;
          orders.euroWithoutTva.price += element.tariff;
          break;
        case element.currency === 'EUR' && element.paymentType !== 'CONTRACT':
          orders.euroWithTva.number++;
          orders.euroWithTva.price += element.tariff;
          break;
        case element.currency === 'RON' && element.paymentType === 'CONTRACT':
          orders.ronWithoutTva.number++;
          orders.ronWithoutTva.price += element.tariff;
          break;
        default:
          orders.ronWithTva.number++;
          orders.ronWithTva.price += element.tariff;
      }
    }
  });

  let totalPriceStatistics = '';
  if (returnWithoutVATObject) {
    return {
      eur: { number: orders.euroWithoutTva.number, price: orders.euroWithoutTva.price.toFixed(2) },
      ron: { number: orders.ronWithoutTva.number, price: orders.ronWithoutTva.price.toFixed(2) },
    };
  } else {
    totalPriceStatistics += getPriceTotal(orders.euroWithoutTva, 'euro without');
    totalPriceStatistics += getPriceTotal(orders.euroWithTva, 'euro with');
    totalPriceStatistics += getPriceTotal(orders.ronWithoutTva, 'ron without');
    totalPriceStatistics += getPriceTotal(orders.ronWithTva, 'ron with');
  }
  return totalPriceStatistics;
};

export const getSelectedTransportsTariffServicesAndTarrifs = (
  selectedTransports: TransportType[]
) => {
  return selectedTransports.map((transport) => {
    return {
      transportId: transport.id,
      transportTariffService: transport.tariffServiceName,
      transportTariff: transport.tariff,
      transportPaymentType: transport.paymentType,
      transportCurrency: transport.currency,
    };
  });
};

export const getUpdatedTransportWithRentExchangeRate = ({
  transport,
  rentExchangeRate,
}: {
  transport: TransportType;
  rentExchangeRate: number;
}) => {
  const futureTransport = { ...transport };
  futureTransport.priceDetails = { ...futureTransport.priceDetails, rentExchangeRate };
  return futureTransport;
};
