import { FC, memo } from "react"
import { convertFieldName } from "../utils"
import { FieldType } from "../../../../utils/field.utils"
import { TemplateType } from "../../../../context/edit-template-context"
import { TransportType } from "./transportTypes"
import { transportFieldsTariff } from "./tariffUtils"

export const Field: FC<{
  name: string
  style: any
  generateTransportField: any
  field: any
  transport: TransportType
  setTransport: any
  isFormLoading: boolean
}> = memo(
  ({ name, style, transport, generateTransportField, field }) => {
    if (!transport.id && field.type === 'transportPhotos') {
      return null
    }

    if (field.type === 'hiddenInput' || field.type === 'viewModeHelper') {
      return generateTransportField(field)
    }
    return (
      <div
        key={`generatedField-${name}`}
        className={`${name}-card-field pb-7 card-field`}
        style={style}
      >
        {field && generateTransportField(field)}
      </div>
    )
  },
  (prev, next) => {
    const fieldName = next.field?.name || next.name
    if(prev.isFormLoading !== next.isFormLoading && transportFieldsTariff.has(fieldName)) {
      return false;
    }
    const name = (convertFieldName as any)[fieldName] || fieldName
    /* select-urile vehicle model sunt fortate sa se rerandeze si atunci
    cand select-urile vehicle brand au o valoare noua selectata */

    if (name === 'assistanceVehicleModel') {
      return (
        prev.transport['assistanceVehicleBrand'] === next.transport['assistanceVehicleBrand'] &&
        prev.transport[name] === next.transport[name]
      )
    }

    if (name === 'replacementVehicleModel') {
      return (
        prev.transport['replacementVehicleBrand'] === next.transport['replacementVehicleBrand'] &&
        prev.transport[name] === next.transport[name]
      )
    }
    if (
      Array.isArray(name) &&
      (name[0] === 'poiOrigin' || name[0] === 'poiDestination' || name[0] === 'assignedDriver')
    ) {
      return false
    }
    /* daca proprietatea din transport este un obiect nested in transport 
    atunci in name vom avea un array care va contine numele proprietati 
    de tip obiect si numele unei proprietati din acel obiect */
    if (Array.isArray(name)) {
      // branch-urile if else de mai jos sunt folosite pentru null checks
      // atat transportul din starea veche cat si cel din starea noua au proprietatea obiect undefined
      if (prev.transport[name[0]] == undefined && next.transport[name[0]] == undefined) {
        return true
      } else {
        // proprietatea de tip obiect e undefined doar in starea veche sau in cea noua, dar nu in ambele
        if (prev.transport[name[0]] == undefined || next.transport[name[0]] == undefined) {
          return false
        } else {
          /* proprietatea de tip obiect nu e undefined nici in starea veche nici in cea noua si prin urmare 
          se poate face verificarea egalitatii pe proprietatea din obiect*/
          return prev.transport[name[0]][name[1]] === next.transport[name[0]][name[1]]
        }
      }
    }
    if (
      name === 'greeter' ||
      name === 'announcedDelay' ||
      name === 'clientSpecs' ||
      name === 'transportCodeCode' ||
      next.field.type === 'customInput' ||
      name === 'poiOrigin' ||
      name === 'poiDestination' ||
      name === 'paymentType' ||
      name === 'intermediaryPoints' ||
      name === 'repeat' ||
      name === 'replacementVehicleReturnEstimateDate' ||
      name === 'driverKm' ||
      name === 'paymentStatus' ||
      name === 'type' ||
      name === 'currency' ||
      name === 'tariff' ||
      name === 'commission' ||
      name === 'supplier' ||
      name === 'revenueCenter' ||
      name === 'tariffServiceId' ||
      name === 'passengerName' ||
      name === 'otherPassengers' ||
      // subscription fields vvvv
      name === 'searchSubscriptions' ||
      name === 'displaySubscriptions' ||
      // ^^^^
      next.field.type === 'breakdownInfo' ||
      next.field.type === 'courseMap' ||
      next.field.type === 'geolocation'
      // first version of the rent fields
      || name === 'rentalPeriod'
      || name === 'vehicleClass'
      || name === 'extraOptions'
      || name === 'dislocationType'
      || name === 'insurance'
      || name === 'discount'
    ) {
      return false
    }
    return prev.transport[name] === next.transport[name]
  }
)

export const Card: FC<{ name: string; children: any, isPlanCreated?: boolean }> = ({ name, children, isPlanCreated }) => {
  return (
    <div id={`${name}-card`} className={`${name}-card card`}>
      {/* begin::Header */}
      <div className='card-header pt-5 border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span style={isPlanCreated ? { opacity: 1 } : { opacity: 0.5 }} className='card-label fw-bolder fs-3 mb-1'>{name}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 sortable-zone autonom-dynamic-card'>{children}</div>
      {/* end::Body */}
    </div>
  )
}

export const scrollIntoView = (id: string) => {
  const element = document.getElementById(id)

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  }
}

export type TemplateInfoType = {
  flatFields: FieldType[]
  template: TemplateType
}

export const getTemplateInfoObject = (fields: FieldType[], template: TemplateType): TemplateInfoType | null => {
  if (!fields || !template?.panels) {
    return null
  }
  const fieldsMap = fields.reduce((fieldMap: Object, field: FieldType) => {
    fieldMap[field.name] = field
    return fieldMap
  }, {})
  const flatFields: FieldType[] = []
  const futureTemplate: TemplateType = JSON.parse(JSON.stringify(template))
  futureTemplate.panels = template.panels.map((panel) => {
    const futurePanel = JSON.parse(JSON.stringify(panel))
    futurePanel.fields = panel.fields.map((templateField) => {
      const futureField = { ...fieldsMap[templateField.name], ...templateField }
      flatFields.push(futureField)
      return futureField
    })
    return futurePanel
  })
  return { flatFields, template: futureTemplate }
}