import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
// Axios
import { Chart, registerables } from 'chart.js'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'

// Apps
import { App } from './app/App'
import { ToastContainer } from 'react-toastify'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 **/
import './_metronic/assets/sass/style.scss'
import AppProvider from './context/app.context'
import UserProvider from './context/user.context'
import CarsProvider from './context/cars.context'
import LogsProvider from './context/logs.context'
import UsersProvider from './context/users.context'
import TemplatesProvider from './context/templates.context'
import TransportsProvider from './context/transports.context'
import ClientProvider from './context/client.context'
import VehiclesProvider from './context/vehicles.context'
import CompanyProvider from './context/company.context'
import EditTemplateProvider from './context/edit-template-context'
import GeofenceProvider from './context/geofence.context'
import PackageProvider from './context/packages.context'
import SubscriptionsProvider from './context/subscriptions.context'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <ToastContainer
      position='bottom-right'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <AppProvider>
      <UserProvider>
        <ClientProvider>
          <UsersProvider>
            <CarsProvider>
              <LogsProvider>
                <TemplatesProvider>
                  <TransportsProvider>
                    <VehiclesProvider>
                      <EditTemplateProvider>
                        <CompanyProvider>
                          <PackageProvider>
                            <GeofenceProvider>
                              <SubscriptionsProvider>
                                <App basename={PUBLIC_URL} />
                              </SubscriptionsProvider>
                            </GeofenceProvider>
                          </PackageProvider>
                        </CompanyProvider>
                      </EditTemplateProvider>
                    </VehiclesProvider>
                  </TransportsProvider>
                </TemplatesProvider>
              </LogsProvider>
            </CarsProvider>
          </UsersProvider>
        </ClientProvider>
      </UserProvider>
    </AppProvider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
